import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BarChart from './complements/grafica_default';

const Dashboard_admin_default = () => {
    const [proyectos, setProyectos] = useState(0);
    const [vendedores, setVendedores] = useState(0);
    const [proyectos_grafica, setProyectos_grafica] = useState(0);
    let path_api = localStorage.getItem('path_api');

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));

        if (!user) return;

        axios.get(`${path_api}/api/proyectos?type=all_count&lvl=${user.lvl}`)
        .then(response => {
            setProyectos(response.data);
        }).catch(error => {
            console.error('Hubo un error al obtener los proyectos:', error);
        });

        axios.get(`${path_api}/api/vendedores?type=all_count&lvl=${user.lvl}`)
        .then(response => {
            setVendedores(response.data);
        })
        .catch(error => {
            console.error('Hubo un error al obtener los vendedores:', error);
        }); 

        axios.get(`${path_api}/api/proyectos?type=12_mouths&lvl=${user.lvl}`)
        .then(response => {
            setProyectos_grafica(response.data);
        }).catch(error => {
            console.error('Hubo un error al obtener los proyectos:', error);
        });
    }, []);

    return (
        <div className='flex-column gap-40'>
            <h2>Dashboard</h2>
            <div className='flex-row gap-40'>
                <div className='container-metrics'>
                    <div className='container-svg background-green'>
                        <svg width="70" viewBox="0 0 100 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M40.625 0.25C38.1386 0.25 35.754 1.23772 33.9959 2.99587C32.2377 4.75403 31.25 7.1386 31.25 9.625V12.75H9.375C6.8886 12.75 4.50403 13.7377 2.74587 15.4959C0.98772 17.254 0 19.6386 0 22.125L0 30.775L47.5875 43.4625C49.1683 43.8835 50.8317 43.8835 52.4125 43.4625L100 30.775V22.125C100 19.6386 99.0123 17.254 97.2541 15.4959C95.496 13.7377 93.1114 12.75 90.625 12.75H68.75V9.625C68.75 7.1386 67.7623 4.75403 66.0041 2.99587C64.246 1.23772 61.8614 0.25 59.375 0.25H40.625ZM40.625 6.5H59.375C60.2038 6.5 60.9987 6.82924 61.5847 7.41529C62.1708 8.00134 62.5 8.7962 62.5 9.625V12.75H37.5V9.625C37.5 8.7962 37.8292 8.00134 38.4153 7.41529C39.0013 6.82924 39.7962 6.5 40.625 6.5Z" fill="black" />
                            <path d="M0 72.125C0 74.6114 0.98772 76.996 2.74587 78.7541C4.50403 80.5123 6.8886 81.5 9.375 81.5H90.625C93.1114 81.5 95.496 80.5123 97.2541 78.7541C99.0123 76.996 100 74.6114 100 72.125V36.8125L50.8062 49.9187C50.278 50.0598 49.722 50.0598 49.1937 49.9187L0 36.8125V72.125Z" fill="black" />
                        </svg>
                    </div>
                    <div className='flex-column padding-20 gap-20'>
                        <h4>Proyectos</h4>
                        <h3>{proyectos}</h3>
                    </div>
                </div>

                <div className='container-metrics'>
                    <div className='container-svg background-orange'>
                        <svg width="70" viewBox="0 0 100 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M43.75 75.5C43.75 75.5 37.5 75.5 37.5 69.25C37.5 63 43.75 44.25 68.75 44.25C93.75 44.25 100 63 100 69.25C100 75.5 93.75 75.5 93.75 75.5H43.75ZM68.75 38C73.7228 38 78.4919 36.0246 82.0083 32.5083C85.5246 28.9919 87.5 24.2228 87.5 19.25C87.5 14.2772 85.5246 9.50806 82.0083 5.99175C78.4919 2.47544 73.7228 0.5 68.75 0.5C63.7772 0.5 59.0081 2.47544 55.4917 5.99175C51.9754 9.50806 50 14.2772 50 19.25C50 24.2228 51.9754 28.9919 55.4917 32.5083C59.0081 36.0246 63.7772 38 68.75 38ZM32.6 75.5C31.6739 73.5487 31.2119 71.4096 31.25 69.25C31.25 60.7812 35.5 52.0625 43.35 46C39.4324 44.7902 35.3498 44.1998 31.25 44.25C6.25 44.25 0 63 0 69.25C0 75.5 6.25 75.5 6.25 75.5H32.6ZM28.125 38C32.269 38 36.2433 36.3538 39.1735 33.4235C42.1038 30.4933 43.75 26.519 43.75 22.375C43.75 18.231 42.1038 14.2567 39.1735 11.3265C36.2433 8.3962 32.269 6.75 28.125 6.75C23.981 6.75 20.0067 8.3962 17.0765 11.3265C14.1462 14.2567 12.5 18.231 12.5 22.375C12.5 26.519 14.1462 30.4933 17.0765 33.4235C20.0067 36.3538 23.981 38 28.125 38Z" fill="black" />
                        </svg>
                    </div>
                    <div className='flex-column padding-20 gap-20'>
                        <h4>Vendedores</h4>
                        <h3>{vendedores}</h3>
                    </div>
                </div>
            </div>

            <div className='container-grafica'>
                <h4>Estadisticas</h4>
                <h3>Proyectos creados en los ultimos 12 meses</h3>
                <BarChart value={proyectos_grafica}></BarChart>
            </div>
        </div>
    );
};

export default Dashboard_admin_default;