import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './../../components/admin/NavbarTop.css';
import Logo from './../../components/logo.png'
import * as globals from './../../components/all.js';
import axios from 'axios';

const NavbarTop = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMenuOpen, setMenuIsOpen] = useState(false);
    const [isDatosOpen, setIsDatosOpen] = useState(false); 
    const [userToEdit, setUserToEdit] = useState(null);
    let user = JSON.parse(localStorage.getItem('user'));
    let path_api = localStorage.getItem('path_api');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleMenuDropdown = () => {
        setMenuIsOpen(!isMenuOpen);
    };

    const handleLogout = async (e) => {
        globals.openViewCharge();
        await axios.post(`${path_api}/api/logout`)
        .then(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                window.location.href = '/login';
            }).catch(error => {
                console.error('Error:', error);
            });
            globals.closeViewCharge();
    };

    const handleEditUser  = async () => {
        if (userToEdit.password !== userToEdit.confirmPassword) {
            alert('Las contraseñas no coinciden');
            return;
        }

        const updatedUser = {
            password: userToEdit.password,
        };

        globals.openViewCharge();
        await axios.put(`${path_api}/api/mis-datos/${user.id}?lvl=${user.lvl}`, updatedUser)
        .then(response => {
            setIsDatosOpen(false);
        })
        .catch(error => {
            console.error('Hubo un error al actualizar la contraseña:', error);
            alert(error.message);
        });
        globals.closeViewCharge(); 
    };

    return (
        <div className="navbar-top">
            <div className="navbar-left">
                <img src={Logo} alt='Logo' />
            </div>
            <div className="navbar-right">
                <span className="username">{user.name}</span>
                <button onClick={toggleDropdown} className="dropdown-toggle desk">
                    ⚙️
                </button>
                {isOpen && (
                    <div className="dropdown-menu">
                        <button onClick={() => {
                            setUserToEdit({password: '', confirmPassword: '' });
                            setIsDatosOpen(true);
                        }}>
                            Mis datos
                        </button>
                        <button onClick={handleLogout}>Cerrar Sesión</button>
                    </div>
                )}
                <button onClick={toggleMenuDropdown} className="dropdown-toggle mobile">
                    ⚙️
                </button>
                {isMenuOpen && (
                    <div className="dropdown-menu">
                        <button onClick={() => {
                            setUserToEdit({password: '', confirmPassword: '' });
                            setIsDatosOpen(true);
                        }}>
                            Mis datos
                        </button>
                        <Link to="/dashboard/admin/">Inicio</Link>
                        <Link to="/dashboard/admin/ordenes">Órdenes</Link>
                        <Link to="/dashboard/admin/vendedores">Vendedores</Link>
                        <Link to="/dashboard/admin/configuracion">Configuración</Link>
                        <button onClick={handleLogout}>Cerrar Sesión</button>
                    </div>
                )}
            </div>
            {isDatosOpen && (
                <div className='modal-container'>
                    <div className="modal">
                        <h2>Datos</h2>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Nombre: </label>
                                <input
                                    type="text"
                                    placeholder="Nombre"
                                    value={user.name}
                                    disabled
                                />
                            </div>
                            <div className='flex-column gap-5'>
                                <label>Correo: </label>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={user.email}
                                    disabled
                                />
                                </div>
                        </div>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Contraseña: </label>
                                <input
                                    type="password"
                                    placeholder="Nueva Contraseña (opcional)"
                                    value={userToEdit.password}
                                    onChange={(e) => setUserToEdit({ ...userToEdit, password: e.target.value })}
                                />
                            </div>
                            <div className='flex-column gap-5'>
                                <label>Confirmación: </label>
                                <input
                                    type="password"
                                    placeholder="Confirmar Nueva Contraseña"
                                    value={userToEdit.confirmPassword}
                                    onChange={(e) => setUserToEdit({ ...userToEdit, confirmPassword: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='flex-row gap-20 flex-end'>
                            <button className='cancel' onClick={() => setIsDatosOpen(false)}>Cancelar</button>
                            <button onClick={handleEditUser}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavbarTop;