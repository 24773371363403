import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as globals from './../../components/all.js';

const Dashboard_admin_ordenes = () => {
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [proyectos, setProyectos] = useState([]); 
    const user = JSON.parse(localStorage.getItem('user'));
    let path_api = localStorage.getItem('path_api');

    useEffect(() => {
        if(!user) return;
        fetchSellers();
    }, [search, currentPage]);

    const fetchSellers = async () => {
        await axios.get(`${path_api}/api/proyectos?type=all_signatures&lvl=${user.lvl}`, { params: { search, page: currentPage } })
        .then(response => {
            console.log(response.data.data);
            setProyectos(response.data.data);
            setTotalPages(response.data.last_page); 
        })
        .catch(error => {
            console.error('Hubo un error al obtener las ordenes:', error);
            alert('Hubo un error al obtener las ordenes');
        });
    };

    const open_pdf = async (id) => {
        globals.openViewCharge();
        await axios.get(`${path_api}/api/generate-pdf?id_proyecto=${id}&path=${path_api}`, {
            responseType: 'blob',
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            
            window.open(url, '_blank');
            
        }).catch(error => {
            console.error('Error:', error);
        });
        globals.closeViewCharge();
    };

    const changePage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className='flex-column gap-40'>
            <h2>Ordenes</h2>
            <div className='container-nav-search'>
                <div>
                    <label>Filtrar</label>
                    <input
                        type="text"
                        placeholder="Escribe para filtrar por nombre, vendedor o cliente"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='container-table'>
                <table>
                    <thead>
                        <tr>
                            <th style={{maxWidth: '200px'}}>Folio</th>
                            <th>Fecha inicio</th>
                            <th>Nombre</th>
                            <th>Vendedor</th>
                            <th>Cliente</th>
                            <th style={{maxWidth: '200px'}}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proyectos.map((proyecto) => (
                            <tr key={proyecto.id}>
                                <td style={{maxWidth: '200px'}}>{proyecto.id}</td>
                                <td>{proyecto.date_start ? proyecto.date_start.slice(0, 10) : <s>Sin fecha</s>}</td>
                                <td>{proyecto.name}</td>
                                <td>{proyecto.user.name}</td>
                                <td>{proyecto.name_client}</td>
                                <td className='gap-20' style={{maxWidth: '200px'}}>
                                    <button className='button-transparent' onClick={() => open_pdf(proyecto.id)}>
                                    <svg width="30" height="30" viewBox="0 0 76 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.5187 65.15C23.3937 64.6333 24.35 64.1375 25.3875 63.6625C24.5101 64.9685 23.5716 66.2325 22.575 67.45C20.825 69.5562 19.4625 70.675 18.6062 71.025L18.3875 71.1C18.3256 71.0131 18.2712 70.9211 18.225 70.825C17.875 70.1375 17.8875 69.475 18.475 68.575C19.1375 67.5438 20.4687 66.3625 22.5187 65.15ZM37.8625 54.8563C37.1208 55.0104 36.3792 55.1729 35.6375 55.3437C36.7396 53.1855 37.7816 50.9972 38.7625 48.7812C39.7502 50.6122 40.8136 52.4012 41.95 54.1438C40.5917 54.3438 39.2292 54.5813 37.8625 54.8563ZM53.6438 60.725C52.6749 59.9398 51.7661 59.0833 50.925 58.1625C52.3583 58.1917 53.6333 58.3042 54.75 58.5C56.7313 58.8562 57.6625 59.4188 57.9875 59.8063C58.0875 59.916 58.1451 60.0578 58.15 60.2063C58.1271 60.647 57.9985 61.0757 57.775 61.4563C57.6507 61.7618 57.4481 62.0291 57.1875 62.2312C57.0599 62.3139 56.9066 62.3472 56.7563 62.325C56.1938 62.3063 55.1438 61.9125 53.6438 60.725ZM39.7375 31.0625C39.4875 32.5875 39.0625 34.3375 38.4875 36.2437C38.2764 35.5298 38.0908 34.8085 37.9312 34.0813C37.4562 31.875 37.3875 30.1438 37.6437 28.9438C37.8812 27.8375 38.3313 27.3938 38.8688 27.175C39.1565 27.0464 39.462 26.9622 39.775 26.925C39.8563 27.1125 39.95 27.5 39.975 28.1625C40.0083 28.925 39.9292 29.8938 39.7375 31.0688V31.0625Z" fill="var(--color-black)"/>
                                        <path d="M13 0H63C66.3152 0 69.4946 1.31696 71.8388 3.66117C74.183 6.00537 75.5 9.18479 75.5 12.5V87.5C75.5 90.8152 74.183 93.9946 71.8388 96.3388C69.4946 98.683 66.3152 100 63 100H13C9.68479 100 6.50537 98.683 4.16116 96.3388C1.81696 93.9946 0.5 90.8152 0.5 87.5V12.5C0.5 9.18479 1.81696 6.00537 4.16116 3.66117C6.50537 1.31696 9.68479 0 13 0ZM14.0312 72.925C14.5937 74.05 15.4688 75.0687 16.7688 75.5437C18.0625 76.0125 19.3438 75.7938 20.3938 75.3563C22.3813 74.5438 24.3625 72.6312 26.1813 70.4437C28.2625 67.9375 30.45 64.65 32.5625 61.0062C36.6404 59.7959 40.8169 58.9468 45.0437 58.4687C46.9187 60.8625 48.8562 62.925 50.7312 64.4063C52.4812 65.7813 54.5 66.925 56.5687 67.0125C57.6954 67.0666 58.8106 66.7648 59.7562 66.15C60.725 65.5187 61.4438 64.6063 61.9688 63.55C62.5313 62.4188 62.875 61.2375 62.8312 60.0312C62.7936 58.8416 62.3529 57.7 61.5812 56.7937C60.1687 55.1062 57.8562 54.2937 55.5812 53.8875C52.8213 53.4623 50.0215 53.3574 47.2375 53.575C44.8879 50.2508 42.8382 46.7244 41.1125 43.0375C42.675 38.9125 43.8437 35.0125 44.3625 31.825C44.5875 30.4625 44.7062 29.1625 44.6625 27.9875C44.6543 26.8212 44.383 25.6718 43.8688 24.625C43.5722 24.0474 43.1509 23.543 42.6352 23.1484C42.1196 22.7538 41.5226 22.4789 40.8875 22.3438C39.625 22.075 38.325 22.3437 37.1313 22.825C34.775 23.7625 33.5313 25.7625 33.0625 27.9687C32.6063 30.0937 32.8125 32.5688 33.35 35.0688C33.9 37.6063 34.8375 40.3688 36.0375 43.1625C34.1136 47.9343 31.8968 52.5827 29.4 57.0813C26.1796 58.0963 23.075 59.4475 20.1375 61.1125C17.825 62.4875 15.7687 64.1125 14.5312 66.0312C13.2187 68.0687 12.8125 70.4938 14.0312 72.925Z" fill="var(--color-black)"/>
                                    </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination">
                    <div>
                        <span>Mostrando <b>{proyectos.length}</b> ordenes</span>
                    </div>
                    <div>
                        <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>Página <b>{currentPage}</b> de <b>{totalPages}</b></span>
                        <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard_admin_ordenes;