import React from 'react';
import './../components/dashboard.css';
import NavbarTop from './NavbarTop';
import NavbarBottom from './NavbarBottom';
import { Outlet } from 'react-router-dom';

const Vendedor_static = () => {
    return (
        <div className="app-container">
            <NavbarTop />
            <div className="page-container max-width-desk">
                <Outlet />
            </div>
            <NavbarBottom />
        </div>
    );
};

export default Vendedor_static;