import React from 'react';
import { Link } from 'react-router-dom';
import './../../components/admin/Sidebar.css';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <ul>
                <li>
                    <Link to="/dashboard/admin/">Inicio</Link>
                </li>
                <li>
                    <Link to="/dashboard/admin/ordenes">Órdenes</Link>
                </li>
                <li>
                    <Link to="/dashboard/admin/vendedores">Vendedores</Link>
                </li>
                <li>
                    <Link to="/dashboard/admin/configuracion">Configuración</Link>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;