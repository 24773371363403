import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './../components/SignaturePad.css';
import * as globals from './../components/all.js';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SignaturePad = () => {
    const { id } = useParams();
    const sigCanvas = useRef({});
    const [signature, setSignature] = useState(null);
    const navigate = useNavigate();
    let path_api = localStorage.getItem('path_api');

    const saveSignature = async () => {
        const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setSignature(signatureData);

        globals.openViewCharge();
        await axios.post(`${path_api}/api/save-signature?id_proyecto=${id}`, { signature: signatureData })
        .then(response => {
                alert(response.data.message);
                navigate(-1);
            }).catch(error => {
                console.error('Error:', error);
            });
        globals.closeViewCharge();
    };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  return (
    <div className='signature-container'>
        <div><SignatureCanvas ref={sigCanvas} canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }} /></div>
        <div className='flex-row gap-20 flex-end'>
            <button className='cancel' onClick={clearSignature}>Limpiar Firma</button>
            <button onClick={saveSignature}>Guardar Firma</button>
        </div>

      {signature && <img src={signature} alt="Firma" />}
    </div>
  );
};

export default SignaturePad;
