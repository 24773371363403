import React from 'react';
import './../components/dashboard.css';
import { Outlet } from 'react-router-dom';
import NavbarTop from './admin/Dashboard_admin_navbartop';
import Sidebar from './admin/Dashboard_admin_sidebar';

const Dashboard_admin = () => {
    return (
        <div className="app-container">
            <NavbarTop />
            <div className="main-content">
                <Sidebar />
                <div className="page-container">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Dashboard_admin;