import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as globals from './../components/all.js';
import './../components/NavbarBottom.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

const NavbarBottom = () => {
    const { id } = useParams();
    const [currentPath, setCurrentPath] = useState('');
    const [proyect, setProyect] = useState({ firma: ''});
    const location = useLocation();
    let user = JSON.parse(localStorage.getItem('user'));
    let path_api = localStorage.getItem('path_api');
    
    useEffect(() => {
        setCurrentPath(location.pathname);

        fetchProyect();
    }, [location]); 

    const fetchProyect = async () => {
        if(!id)
            return;
        axios.get(`${path_api}/api/proyectos?type=unique&lvl=${user.lvl}&id_user=${user.id}&id_proyecto=${id}`)
        .then(response => {
            setProyect(response.data);
        }).catch(error => {
            console.error('Hubo un error al obtener el proyecto:', error);
        });

    };

    const handleLogout = async (e) => {
        globals.openViewCharge();
        await axios.post(`${path_api}/api/logout`)
        .then(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                window.location.href = '/login';
            }).catch(error => {
                console.error('Error:', error);
            });
            globals.closeViewCharge();
    };

    const handleDownload = async (e) => {
        globals.openViewCharge();
        await axios.get(`${path_api}/api/generate-pdf?id_proyecto=${id}&path=${path_api}`, {
            responseType: 'blob',
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            
            window.open(url, '_blank');
            
        }).catch(error => {
            console.error('Error:', error);
        });
        globals.closeViewCharge();
    };

    if(currentPath == '/')
        return (
            <div className="navbar-bottom">
                <button onClick={handleLogout}>
                    <svg width="30" height="30" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.25 50C6.25 61.6032 10.8594 72.7312 19.0641 80.9359C27.2688 89.1406 38.3968 93.75 50 93.75C61.6032 93.75 72.7312 89.1406 80.9359 80.9359C89.1406 72.7312 93.75 61.6032 93.75 50C93.75 38.3968 89.1406 27.2688 80.9359 19.0641C72.7312 10.8594 61.6032 6.25 50 6.25C38.3968 6.25 27.2688 10.8594 19.0641 19.0641C10.8594 27.2688 6.25 38.3968 6.25 50ZM100 50C100 63.2608 94.7322 75.9785 85.3553 85.3553C75.9785 94.7322 63.2608 100 50 100C36.7392 100 24.0215 94.7322 14.6447 85.3553C5.26784 75.9785 0 63.2608 0 50C0 36.7392 5.26784 24.0215 14.6447 14.6447C24.0215 5.26784 36.7392 0 50 0C63.2608 0 75.9785 5.26784 85.3553 14.6447C94.7322 24.0215 100 36.7392 100 50ZM28.125 46.875C27.2962 46.875 26.5013 47.2042 25.9153 47.7903C25.3292 48.3763 25 49.1712 25 50C25 50.8288 25.3292 51.6237 25.9153 52.2097C26.5013 52.7958 27.2962 53.125 28.125 53.125H64.3313L50.9125 66.5375C50.6219 66.828 50.3915 67.173 50.2342 67.5526C50.077 67.9322 49.9961 68.3391 49.9961 68.75C49.9961 69.1609 50.077 69.5678 50.2342 69.9474C50.3915 70.327 50.6219 70.672 50.9125 70.9625C51.203 71.2531 51.548 71.4835 51.9276 71.6408C52.3072 71.798 52.7141 71.8789 53.125 71.8789C53.5359 71.8789 53.9428 71.798 54.3224 71.6408C54.702 71.4835 55.047 71.2531 55.3375 70.9625L74.0875 52.2125C74.3785 51.9222 74.6094 51.5774 74.767 51.1977C74.9245 50.8181 75.0056 50.411 75.0056 50C75.0056 49.589 74.9245 49.1819 74.767 48.8023C74.6094 48.4226 74.3785 48.0778 74.0875 47.7875L55.3375 29.0375C55.047 28.7469 54.702 28.5165 54.3224 28.3592C53.9428 28.202 53.5359 28.1211 53.125 28.1211C52.7141 28.1211 52.3072 28.202 51.9276 28.3592C51.548 28.5165 51.203 28.7469 50.9125 29.0375C50.6219 29.328 50.3915 29.673 50.2342 30.0526C50.077 30.4322 49.9961 30.8391 49.9961 31.25C49.9961 31.6609 50.077 32.0678 50.2342 32.4474C50.3915 32.827 50.6219 33.172 50.9125 33.4625L64.3313 46.875H28.125Z" fill="var(--color-white)"/>
                    </svg>
                    <span>SALIR</span>
                </button>
            </div>
        );
    else if(currentPath.startsWith('/items') && proyect.firma)
        return (
            <div className="navbar-bottom">
                <button onClick={handleDownload}>
                    <svg width="30" height="30" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.25 50C6.25 61.6032 10.8594 72.7312 19.0641 80.9359C27.2688 89.1406 38.3968 93.75 50 93.75C61.6032 93.75 72.7312 89.1406 80.9359 80.9359C89.1406 72.7312 93.75 61.6032 93.75 50C93.75 38.3968 89.1406 27.2688 80.9359 19.0641C72.7312 10.8594 61.6032 6.25 50 6.25C38.3968 6.25 27.2688 10.8594 19.0641 19.0641C10.8594 27.2688 6.25 38.3968 6.25 50ZM100 50C100 63.2608 94.7322 75.9785 85.3553 85.3553C75.9785 94.7322 63.2608 100 50 100C36.7392 100 24.0215 94.7322 14.6447 85.3553C5.26784 75.9785 0 63.2608 0 50C0 36.7392 5.26784 24.0215 14.6447 14.6447C24.0215 5.26784 36.7392 0 50 0C63.2608 0 75.9785 5.26784 85.3553 14.6447C94.7322 24.0215 100 36.7392 100 50ZM28.125 46.875C27.2962 46.875 26.5013 47.2042 25.9153 47.7903C25.3292 48.3763 25 49.1712 25 50C25 50.8288 25.3292 51.6237 25.9153 52.2097C26.5013 52.7958 27.2962 53.125 28.125 53.125H64.3313L50.9125 66.5375C50.6219 66.828 50.3915 67.173 50.2342 67.5526C50.077 67.9322 49.9961 68.3391 49.9961 68.75C49.9961 69.1609 50.077 69.5678 50.2342 69.9474C50.3915 70.327 50.6219 70.672 50.9125 70.9625C51.203 71.2531 51.548 71.4835 51.9276 71.6408C52.3072 71.798 52.7141 71.8789 53.125 71.8789C53.5359 71.8789 53.9428 71.798 54.3224 71.6408C54.702 71.4835 55.047 71.2531 55.3375 70.9625L74.0875 52.2125C74.3785 51.9222 74.6094 51.5774 74.767 51.1977C74.9245 50.8181 75.0056 50.411 75.0056 50C75.0056 49.589 74.9245 49.1819 74.767 48.8023C74.6094 48.4226 74.3785 48.0778 74.0875 47.7875L55.3375 29.0375C55.047 28.7469 54.702 28.5165 54.3224 28.3592C53.9428 28.202 53.5359 28.1211 53.125 28.1211C52.7141 28.1211 52.3072 28.202 51.9276 28.3592C51.548 28.5165 51.203 28.7469 50.9125 29.0375C50.6219 29.328 50.3915 29.673 50.2342 30.0526C50.077 30.4322 49.9961 30.8391 49.9961 31.25C49.9961 31.6609 50.077 32.0678 50.2342 32.4474C50.3915 32.827 50.6219 33.172 50.9125 33.4625L64.3313 46.875H28.125Z" fill="var(--color-white)"/>
                    </svg>
                    <span>GENERAR PDF</span>
                </button>
            </div>
        );
    else if(currentPath.startsWith('/items'))
        return (
            <div className="navbar-bottom">
                <Link to={`/firma/${proyect.id}`}>
                    <svg width="30" height="30" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.25 50C6.25 61.6032 10.8594 72.7312 19.0641 80.9359C27.2688 89.1406 38.3968 93.75 50 93.75C61.6032 93.75 72.7312 89.1406 80.9359 80.9359C89.1406 72.7312 93.75 61.6032 93.75 50C93.75 38.3968 89.1406 27.2688 80.9359 19.0641C72.7312 10.8594 61.6032 6.25 50 6.25C38.3968 6.25 27.2688 10.8594 19.0641 19.0641C10.8594 27.2688 6.25 38.3968 6.25 50ZM100 50C100 63.2608 94.7322 75.9785 85.3553 85.3553C75.9785 94.7322 63.2608 100 50 100C36.7392 100 24.0215 94.7322 14.6447 85.3553C5.26784 75.9785 0 63.2608 0 50C0 36.7392 5.26784 24.0215 14.6447 14.6447C24.0215 5.26784 36.7392 0 50 0C63.2608 0 75.9785 5.26784 85.3553 14.6447C94.7322 24.0215 100 36.7392 100 50ZM28.125 46.875C27.2962 46.875 26.5013 47.2042 25.9153 47.7903C25.3292 48.3763 25 49.1712 25 50C25 50.8288 25.3292 51.6237 25.9153 52.2097C26.5013 52.7958 27.2962 53.125 28.125 53.125H64.3313L50.9125 66.5375C50.6219 66.828 50.3915 67.173 50.2342 67.5526C50.077 67.9322 49.9961 68.3391 49.9961 68.75C49.9961 69.1609 50.077 69.5678 50.2342 69.9474C50.3915 70.327 50.6219 70.672 50.9125 70.9625C51.203 71.2531 51.548 71.4835 51.9276 71.6408C52.3072 71.798 52.7141 71.8789 53.125 71.8789C53.5359 71.8789 53.9428 71.798 54.3224 71.6408C54.702 71.4835 55.047 71.2531 55.3375 70.9625L74.0875 52.2125C74.3785 51.9222 74.6094 51.5774 74.767 51.1977C74.9245 50.8181 75.0056 50.411 75.0056 50C75.0056 49.589 74.9245 49.1819 74.767 48.8023C74.6094 48.4226 74.3785 48.0778 74.0875 47.7875L55.3375 29.0375C55.047 28.7469 54.702 28.5165 54.3224 28.3592C53.9428 28.202 53.5359 28.1211 53.125 28.1211C52.7141 28.1211 52.3072 28.202 51.9276 28.3592C51.548 28.5165 51.203 28.7469 50.9125 29.0375C50.6219 29.328 50.3915 29.673 50.2342 30.0526C50.077 30.4322 49.9961 30.8391 49.9961 31.25C49.9961 31.6609 50.077 32.0678 50.2342 32.4474C50.3915 32.827 50.6219 33.172 50.9125 33.4625L64.3313 46.875H28.125Z" fill="var(--color-white)"/>
                    </svg>
                    <span>GENERAR FIRMA (orden de compra)</span>
                </Link>
            </div>
        );
};

export default NavbarBottom;