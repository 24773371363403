import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { addMonths, format } from 'date-fns';

// Registro de los componentes de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ value }) => {
  // Función para generar un arreglo de etiquetas de los últimos 12 meses
  const generateLabels = () => {
    const labels = [];
    for (let i = 12, cont = 0; cont < 2; i-=12, cont++) {
      const month = addMonths(new Date(), -i);
      labels.push(format(month, 'MMM yyyy')); // Formato de los meses: Ene 2023, Feb 2023, etc.
    }
    return labels;
  };

  // Datos para el gráfico
  const data = {
    labels: generateLabels(),
    datasets: [
      {
        label: 'Valor numérico',
        data: new Array(2).fill(value), // Rellenar los 12 meses con el valor numérico
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Color de las barras
      },
    ],
  };

  // Opciones del gráfico
  const options = {
    scales: {
      y: {
        beginAtZero: true, // La escala del eje Y empieza en 0
      },
    },
    plugins: {
      legend: {
        display: false, // Ocultar la leyenda si no es necesaria
      },
      tooltip: {
        enabled: false, // Desactivar los tooltips al pasar el cursor
      },
    },
    hover: {
      mode: null, // Desactiva el hover sobre las barras
    },
    // Aquí ajustamos la separación entre las barras
    barPercentage: 1.0, // Ocupa todo el ancho de la categoría
    categoryPercentage: 1.0, // Sin espacios entre categorías
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
