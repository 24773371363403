import React, { useState } from 'react';
import axios from 'axios';
import * as globals from './../../../components/all.js';

const Modal_configuracion_vectores = ({ onClose, data, onOtherAdded }) => {
    const [name, setName] = useState('');
    const user = JSON.parse(localStorage.getItem('user'));
    let path_api = localStorage.getItem('path_api');

    const handleSubmit = async (type) => {
        globals.openViewCharge();
        await axios.post(`${path_api}/api/config/${type}?lvl=${user.lvl}`, {name})
        .then(response => {
            console.log(response.data)
            onOtherAdded(response.data.other);
        })
        .catch(error => {
            console.error('Hubo un error al agregar:', error);
            alert('Hubo un error al agregar');
        });
        globals.closeViewCharge();
        onClose();
    };

    return (
        <div className='modal-container'>
            <div className="modal">
                <h2>Agregar {data.type}</h2>
                <div className='flex-column gap-5'>
                    <label>Nombre: </label>
                    <input
                        type="text"
                        placeholder="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className='flex-row gap-20 flex-end'>
                    <button className='cancel' onClick={onClose}>Cerrar</button>
                    <button onClick={() => handleSubmit(data.type)}>Agregar</button>
                </div>
            </div>
        </div>
    );
};

export default Modal_configuracion_vectores;
